import { render, staticRenderFns } from "./PromotionReward2.vue?vue&type=template&id=2ba6e500&scoped=true"
import script from "./PromotionReward2.vue?vue&type=script&lang=js"
export * from "./PromotionReward2.vue?vue&type=script&lang=js"
import style0 from "./PromotionReward2.vue?vue&type=style&index=0&id=2ba6e500&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba6e500",
  null
  
)

export default component.exports